import React from 'react';
import Layout from '../components/layout'
import styles from './contact.module.css'
import Hero from '../components/hero'

const encode =(data)=>{
    return Object.keys(data).map(key => 
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        ).join("&");
}

class Contact extends React.Component{
    state = { 
        name: "", 
        email: "", 
        message: "" };

    handleSubmit = e => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...this.state })
        })
          .then(() => window.location = "/success")
          .catch(error => alert(error));
  
        e.preventDefault();
      };
  
    handleChange = e => this.setState({ [e.target.name]: e.target.value });
    
    render() {
        const { name, email, message } = this.state;
        const [author] = this.props.data.allContentfulPerson.edges
        const image = this.props.data.allImageSharp.edges[0].node.fluid
        return(
        <Layout location={this.props.location}>
            <div style={{ background: '#fff' }} className={styles.container}>
            <Hero data={author.node}  image={image}/>
             <div className="wrapper">
             <p className={styles.title}>Contact</p>
             <form onSubmit={this.handleSubmit} name="contact" action="/success" method="post" netlify-honeypot="bot-field" data-netlify="true" netlify>
                <div>
                      <label htmlFor="name">Name</label>  
                      <input onChange={this.handleChange} value={name} type="text" name="name" id="name" placeholder="Yukio Mishima" required/>
                      <label htmlFor="email">Email</label>
                      <input onChange={this.handleChange} value={email} type="email" name="email" id="email" placeholder="hello@yukio-mishima.com" required/>
                      <label htmlFor="textarea">Message</label>
                      <textarea onChange={this.handleChange} value={message} name="message" rows="10" id="textarea" required></textarea>
                      <br/> 
                      <button type="submit">Send</button>
                </div>
            </form>  
            </div>
            </div>
        </Layout>
        )
}
}

export default Contact;

export const pageQuery = graphql`
query blogIndex {
    allContentfulPerson {
      edges {
        node {
          title
          shortBio {
            shortBio
          }
          name
        }
      }
    }
    allImageSharp {
      edges {
        node {
            fluid(maxWidth: 2000){
                ...GatsbyImageSharpFluid
                originalName
          }
        }
      }
    }
  }
`